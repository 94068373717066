import { useState } from "react";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  FormControl,
  FormLabel,
  TextField,
  Box,
} from "@mui/material";

function PriceSelection({ data }) {
  const [price, setPrice] = useState(parseFloat(data.product.price) || "");
  const [discount, setDiscount] = useState(
    parseFloat(data.product.discount) || ""
  );
  const [salePrice, setSalePrice] = useState(
    parseFloat(data.product.salePrice) || price - discount
  );

  // Calculate sale price based on discount amount
  const handleDiscountChange = (e) => {
    const newDiscount = parseFloat(e.target.value) || 0;
    setDiscount(newDiscount);
    const newSalePrice = (price - newDiscount).toFixed(2);
    setSalePrice(parseFloat(newSalePrice));
  };

  // Calculate discount based on sale price
  const handleSalePriceChange = (e) => {
    const newSalePrice = parseFloat(e.target.value) || ""; // Allow empty input
    setSalePrice(newSalePrice);
    if (newSalePrice !== "") {
      const newDiscount = (price - newSalePrice).toFixed(2);
      setDiscount(parseFloat(newDiscount));
    } else {
      setDiscount(0); // Reset discount if sale price is cleared
    }
  };

  // Update price and recalculate discount and sale price
  const handlePriceChange = (e) => {
    const newPrice = parseFloat(e.target.value) || ""; // Allow empty input
    setPrice(newPrice);
    if (newPrice) {
      const newDiscount = (newPrice - salePrice).toFixed(2);
      setDiscount(parseFloat(newDiscount));
    } else {
      // Reset sale price and discount if price is cleared
      setSalePrice(0);
      setDiscount(0);
    }
  };

  return (
    <Card sx={{ mb: 5, boxShadow: 3 }}>
      <CardHeader
        title="Price Information"
        titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
      />
      <Divider />
      <CardContent>
        {/* Main Price */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="inp-4">Price *</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              size="small"
              id="inp-4"
              name="main_price"
              required
              value={price}
              onChange={handlePriceChange}
              InputProps={{
                inputProps: { min: 0 },
                onWheel: (e) => e.target.blur(),
              }}
            />
          </FormControl>
        </Box>

        {/* Sale Price */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="inp-4">Sale Price *</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              size="small"
              id="inp-4"
              name="sale_price"
              required
              value={salePrice}
              onChange={handleSalePriceChange}
              InputProps={{
                inputProps: { min: 0 },
                onWheel: (e) => e.target.blur(),
              }}
            />
          </FormControl>
        </Box>

        {/* Discount Amount */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="inp-4">Discount Amount *</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              size="small"
              id="inp-4"
              name="discount"
              required
              value={discount}
              onChange={handleDiscountChange}
              InputProps={{
                inputProps: { min: 0 },
                onWheel: (e) => e.target.blur(),
              }}
            />
          </FormControl>
        </Box>

        {/* Disabled Discount Percentage */}
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="inp-5">Discount in Percentage</FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              size="small"
              id="inp-5"
              aria-readonly
              name="discountPercentage"
              required
              value={price > 0 ? Math.round((discount / price) * 100) : 0} // Round off percentage
              InputProps={{
                inputProps: { min: 0, max: 100 },
                onWheel: (e) => e.target.blur(),
              }}
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PriceSelection;
