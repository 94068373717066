import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popper,
  Typography,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ImageLoader from "./image/ImageLoader";

const CustomSelect = ({ list, preIndex = 0, dataChange, rootIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(preIndex);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperWidth, setPopperWidth] = useState("auto");

  const handleClick = (newIndex) => {
    setIndex(newIndex);
    setIsOpen(false);
  };

  useEffect(() => {
    const data = {
      target: {
        name: "imageIndex",
        value: index,
      },
    };
    dataChange(data, rootIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    if (anchorEl) {
      setPopperWidth(anchorEl.offsetWidth);
    }
  }, [anchorEl, isOpen]);

  const selectedItem = list[index - 1]?.name || "None";

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Typography variant="body1" component="label">
        Image
      </Typography>
      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          width: "100%",
          mt: 1,
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          endIcon={<ArrowDropDownIcon />}
          onClick={handleButtonClick}
        >
          {selectedItem.slice(0, 20)}
          {"..."}
        </Button>
        <Popper
          open={isOpen}
          placement="bottom-start"
          anchorEl={anchorEl}
          sx={{ zIndex: 1, width: "100%", maxWidth: "450px" }}
        >
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <List
              dense
              sx={{
                mt: 1,
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "4px",
                bgcolor: "background.paper",
                maxHeight: 200,
                overflow: "auto",
                boxShadow: 1,
              }}
            >
              <ListItem selected={index === 0} onClick={() => handleClick(0)}>
                <ListItemText primary="None" />
              </ListItem>
              {list.map((x, i) => (
                <ListItem
                  key={i}
                  selected={i + 1 === index}
                  onClick={() => handleClick(i + 1)}
                >
                  <IconButton edge="start">
                    <ImageLoader src={x.url} width={30} height={30} />
                  </IconButton>
                  <ListItemText primary={x.name} />
                </ListItem>
              ))}
            </List>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};

export default CustomSelect;
