import React, { useState, useEffect } from "react";
import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../../../reusablecomponents/ModalComponent/ModalComponent";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";

const UpdateQuote = ({ handleClose, data, getAllQuotes }) => {
  const [singlequote, setQuote] = useState(data.singledata.quote);
  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    try {
      const res = await axios.put("/api/auth/adminAuth/update/quote", {
        qouteId: data.id,
        quote: singlequote,
        isActive: "true",
      });
      getAllQuotes();
      if (res?.data.success) {
        setQuote("");
        handleClose();
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message: `Quote updated successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("App Ambasder error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <>
      <ModalComponent open={data.open}>
        <Stack
          width={550}
          p={3}
          spacing={2}
          component={"form"}
          onSubmit={submitHandler}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "rgba(27, 32, 40, 1)",
              fontSize: "22px",
            }}
          >
            Edit Quote
          </Typography>
          <TextField
            value={singlequote}
            multiline
            name="quote"
            inputProps={{
              maxLength: 150,
            }}
            required
            onChange={(e) => setQuote(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              type="submit"
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
              }}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              sx={{ ml: 3 }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Stack>
      </ModalComponent>
    </>
  );
};
UpdateQuote.propTypes = {
  handleClose: PropTypes.func,
  data: PropTypes.object,
  getAllQuotes: PropTypes.func,
};
export default UpdateQuote;
