import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, TextField, Typography, IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import { PhotoCamera } from "@mui/icons-material";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import FileUpload from "../../components/FileUpload";

const SEOSettingsForm = ({
  onSubmit,
  initialData,
  setIsFormChanged,
  isFormChanged,
}) => {
  const dispatch = useDispatch();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaImage, setMetaImage] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const handleImageUpload = (newImage) => {
    setMetaImage(newImage);
  };

  const getGlobelSeoSettings = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.get("/api/v1/apeitnow/settings/get/seo");
      setMetaTitle(res?.data?.data?.seo?.title || "");
      setMetaDescription(res?.data?.data?.seo?.description || "");
      setMetaKeywords(res?.data?.data?.seo?.keyword || "");
      setMetaImage(res?.data?.data?.seo?.image || []);
      setPreviewImage(res?.data?.data?.seo?.image?.url || "");
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getGlobelSeoSettings();
    if (initialData) {
      setMetaTitle(initialData.metaTitle || "");
      setMetaDescription(initialData.metaDescription || "");
      setMetaKeywords(initialData.metaKeywords || "");
      setPreviewImage(initialData.metaImage || "");
    }
  }, [initialData]);
  const handleImageChange = (e) => {
    setIsFormChanged(true);

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMetaImage(file);
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    const seoData = {
      metaTitle,
      metaDescription,
      metaKeywords,
      metaImage, // You may need to handle image file uploading separately
    };
    onSubmit(seoData, getGlobelSeoSettings);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <TextField
        label="Meta Title"
        value={metaTitle}
        onChange={(e) => {
          setMetaTitle(e.target.value);
          setIsFormChanged(true);
        }}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Meta Description"
        value={metaDescription}
        onChange={(e) => {
          setMetaDescription(e.target.value);
          setIsFormChanged(true);
        }}
        fullWidth
        margin="normal"
        multiline
        rows={3}
      />

      <TextField
        label="Meta Keywords"
        value={metaKeywords}
        onChange={(e) => {
          setMetaKeywords(e.target.value);
          setIsFormChanged(true);
        }}
        fullWidth
        margin="normal"
        helperText="Separate keywords with commas"
      />

      <Box sx={{ mt: 2 }}>
        <label htmlFor="upload-button">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="upload-button"
          />
          <IconButton component="span">
            <PhotoCamera />
          </IconButton>
          <Typography variant="body2" sx={{ ml: 1 }}>
            Upload Meta Image
          </Typography>
        </label>
        {previewImage && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Preview:</Typography>
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "200px", marginTop: "8px" }}
            />
          </Box>
        )}
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ mt: 2 }}
        disabled={!isFormChanged}
      >
        Save SEO Settings
      </Button>
    </Box>
  );
};

SEOSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    metaImage: PropTypes.string,
  }),
  setIsFormChanged: PropTypes.func,
  isFormChanged: PropTypes.bool,
};

export default SEOSettingsForm;
