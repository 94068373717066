import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// mock
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import { selectPanel } from "../../../ReduxToolkit/Slices/adminPanelSlice";

import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
//
import { navConfig } from "./config";
import { apeitNowConfig } from "./apeitNowConfig";
import { getErrorMessage } from "../../../reusablecomponents/CustomError/errorMessages";
import { rolesPermissions } from "../../../reusablecomponents/RolePrmission/dummyRole";
import yellowAvatar from "../../../Assets/maonky_NEW.png";
import apeLabs from "../../../Assets/apeLabs.png";
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};
const panelIcons = {
  ApeBuzz: yellowAvatar,
  ApeLabs: apeLabs,
  ApeItNow: apeLabs, // Assuming the same icon for simplicity
};
export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedPanel = useSelector((state) => state.panel.selectedPanel);
  const [values, setValues] = useState(null);
  const adminProfile = async () => {
    try {
      const res = await axios.get(`/api/auth/adminAuth/get/profile/data`);
      setValues(res?.data.adminData);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    adminProfile();
  }, []);
  const normalize = (str) =>
    str.toLowerCase().replace(/\s+/g, "-").replace(/_/g, "-");

  const getAccessibleRoutes = (navConfig, permissions) => {
    // Extract the allowed resources from permissions
    const allowedResources = permissions?.roles.flatMap((role) =>
      role.permissions.map((permission) => normalize(permission.resource)),
    );
    // Filter navConfig based on allowed resources
    const filteredNavConfig = navConfig.filter((route) =>
      allowedResources.includes(normalize(route.title)),
    );

    return filteredNavConfig;
  };
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ mb: 2, mx: 1.5, pt: 2 }}>
        <Select
          fullWidth
          id="select-admin-panel"
          variant="outlined"
          value={selectedPanel}
          name="adminPanel"
          size="medium"
          onChange={(e) => {
            dispatch(selectPanel(e.target.value));
            if (e.target.value === "ApeBuzz") {
              navigate("/dashboard/home");
            }
            if (e.target.value === "ApeLabs") {
              navigate("/dashboard/apelabs");
            }
            if (e.target.value === "ApeItNow") {
              navigate("/dashboard/apeitnow");
            }
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                alt="panelIcon"
                src={panelIcons[selected]}
                style={{ width: "28px", height: "28px", marginRight: "8px" }}
              />
              {selectedPanel}
            </Box>
          )}
        >
          {["ApeBuzz", "ApeLabs", "ApeItNow"].map((value, index) => (
            <MenuItem
              key={index}
              value={value}
              sx={{
                display: "flex",
                flexDirection: "row",
                // bgcolor: "yellow",
              }}
            >
              <img
                alt="panelIcon"
                src={panelIcons[value]}
                style={{ width: "28px", height: "28px", marginRight: "8px" }}
              />
              <Typography>{value}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selectedPanel === "ApeBuzz" && <NavSection data={navConfig} />}
      {selectedPanel === "ApeLabs" && (
        <NavSection
          data={getAccessibleRoutes(apeitNowConfig, rolesPermissions)}
        />
      )}
      {selectedPanel === "ApeItNow" && (
        <NavSection
          data={getAccessibleRoutes(apeitNowConfig, rolesPermissions)}
        />
      )}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  const ResponsiveRenderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Logo />
        <ArrowBackIosIcon
          sx={{ mt: 2, width: "28px", height: "28px", cursor: "pointer" }}
          onClick={onCloseNav}
        />
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            {values === null ? (
              <Avatar src={account.photoURL} alt="photoURL" />
            ) : (
              <Avatar src={values.profilePicture?.imageUrl} alt="photoURL" />
            )}

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {values?.name}
              </Typography>

              
            </Box>
          </StyledAccount>
        </Link>
      </Box> */}
      <Box sx={{ mb: 2, mx: 1.1 }}>
        <Select
          fullWidth
          id="select-admin-panel"
          variant="outlined"
          value={selectedPanel}
          name="adminPanel"
          size="medium"
          onChange={(e) => {
            dispatch(selectPanel(e.target.value));
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {selectedPanel === "ApeBuzz" ? (
                <img
                  alt="panelIcon"
                  src={yellowAvatar}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
              ) : selectedPanel === "ApeLabs" ? (
                <img
                  alt="panelIcon"
                  src={apeLabs}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
              ) : (
                <img
                  alt="panelIcon"
                  src={apeLabs}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
              )}
              {selectedPanel}
            </Box>
          )}
        >
          {["ApeBuzz", "ApeLabs", "ApeItNow"].map((value, index) => (
            <MenuItem
              key={index}
              value={value}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {value === "ApeBuzz" ? (
                <img
                  alt="panelIcon"
                  src={yellowAvatar}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
              ) : selectedPanel === "ApeLabs" ? (
                <img
                  alt="panelIcon"
                  src={apeLabs}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
              ) : (
                <img
                  alt="panelIcon"
                  src={apeLabs}
                  style={{ width: "28px", height: "28px", marginRight: "8px" }}
                />
              )}
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {ResponsiveRenderContent}
        </Drawer>
      )}
    </Box>
  );
}
