import React, { useEffect, useState } from 'react'
import { Box, Stack, Tab, Tabs } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import RemovedUsers from './RemovedUsers';
import PendingAction from './PendingAction';


const ReportedUsers = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tab = query?.get('tab') || '0';


    const [value, setValue] = useState("PendingAction");
    const handleChange = (event, newValue) => {
        setValue(newValue);
        // navigate(`?tab=${newValue}`);
    };
    // useEffect(() => {
    //     if (!query.get('tab')) {
    //         navigate(`/reported-users`);
    //     }
    // }, [query, navigate]);
    return (
        <Box mt={4}>
            <Box width={"70%"}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    // indicatorColor="rgba(97, 177, 90) !important"
                    aria-label="secondary tabs example"
                    sx={{
                        bgcolor: 'transparent', '& .MuiTabs-indicator': {
                            backgroundColor: 'rgba(97, 177, 90)',
                        },
                    }}

                >
                    <Tab value="PendingAction" label="Pending Action" sx={{
                        bgcolor: 'transparent',
                        color: 'primary',
                        '&.Mui-selected': {
                            bgcolor: 'transparent',
                            color: 'rgba(97, 177, 90, 1) !important',
                        },

                    }} />
                    <Tab value="RemovedUsers" label="Deactivated Users" sx={{
                        bgcolor: 'transparent',
                        color: 'primary',
                        '&.Mui-selected': {
                            bgcolor: 'transparent',
                            color: 'rgba(97, 177, 90, 1) !important',
                        },
                    }} />

                </Tabs>
            </Box>
            <Stack mt={2}>
                {value === "PendingAction" && <PendingAction />}
                {value === "RemovedUsers" && <RemovedUsers />}
            </Stack>
        </Box>
    )
}

export default ReportedUsers