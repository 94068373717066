import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import ProductsPage from "./pages/ProductsPage";
import DashboardAppPage from "./pages/DashboardAppPage";
import SignupPage from "./sections/auth/signup/SignPage";
import ForgetPage from "./sections/auth/Forget/ForgetPage";
import ResetPage from "./sections/auth/Reset/ResetPage";
import UserManagement from "./components/adminSection/UserManagement/UserManagement";
import AppAmbassador from "./components/adminSection/AppCustomization/AppAmbassador/AppAmbassador";
import ManageAvatar from "./components/adminSection/AppCustomization/ManageAvatar/ManageAvatar";
import ManageBadge from "./components/adminSection/AppCustomization/ManageBadge/ManageBadge";
import SoundTone from "./components/adminSection/AppCustomization/SoundTone/SoundTone";
import ForgotPassword from "./pages/ForgotPassword";
import Verification from "./pages/Verification";
import ResetPassword from "./pages/ResetPassword";
import PrivacyPolicy from "./components/adminSection/CMS/PrivacyPolicy";
import TermsConditions from "./components/adminSection/CMS/TermsConditions";
import Feedback from "./components/adminSection/CMS/Feedback";
import ReportedUsers from "./components/adminSection/ReportedUsers/ReportedUsers";
import CustomNotification from "./components/adminSection/CustomNotification/CustomNotification";
import Overview from "./components/adminSection/profile";
import HomePage from "./components/ApeLabsSection/HomePage/HomePage";
import Product from "./layouts/apeitnowdashboard/pages/Product";
import SEOSetting from "./layouts/apeitnowdashboard/settings/SEOSetting";
import Orders from "./layouts/apeitnowdashboard/orders/Orders";
import SingleOrderScreen from "./layouts/apeitnowdashboard/orders/SingleOrderScreen";
import ApeItNowDashboard from "./ApeItNowPannel/DashBoard/ApeItNowDashboard";
import AllProduct from "./ApeItNowPannel/Product/AllProduct";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" /> },
        {
          path: "home",
          element: <DashboardAppPage />,
        },
        { path: "user-management", element: <UserManagement /> },
        {
          path: "app-customization",
          children: [
            {
              index: true,
              element: (
                <Navigate to="/app-customization/app-ambassador" replace />
              ),
            },
            { path: "app-ambassador", element: <AppAmbassador /> },
            { path: "manage-avatar", element: <ManageAvatar /> },
            { path: "manage-badge", element: <ManageBadge /> },
            { path: "sound-tone", element: <SoundTone /> },
          ],
        },
        {
          path: "cms",
          children: [
            { path: "privacy-policy", element: <PrivacyPolicy /> },
            { path: "terms-conditions", element: <TermsConditions /> },
            { path: "feedback", element: <Feedback /> },
          ],
        },
        { path: "reported-users", element: <ReportedUsers /> },
        { path: "custom-notification", element: <CustomNotification /> },
        { path: "profile", element: <Overview /> },
        { path: "apelabs", element: <HomePage /> },
        { path: "apeitnow", element: <ApeItNowDashboard /> },
        { path: "orders", element: <Orders /> },
        { path: "orders/view/:orderId", element: <SingleOrderScreen /> },

        {
          path: "product",
          children: [
            { path: "add-product", element: <Product /> },
            { path: "all-product", element: <AllProduct /> }
          ],
        },
        {
          path: "settings",
          children: [{ path: "seo-setting", element: <SEOSetting /> }],
        },
      ],
    },

    {
      path: "login",
      index: true,
      element: <LoginPage />,
    },
    // {
    //   path: "signup",
    //   element: <SignupPage />,
    // },
    {
      path: "forget",
      element: <ForgotPassword />,
    },
    {
      path: "resetPassword",
      element: <ResetPassword />,
    },
    {
      path: "verification",
      element: <Verification />,
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
