export const rolesPermissions = {
  roles: [
    {
      id: 1,
      name: "super admin",
      description: "Full access to all panels and management capabilities",
      permissions: [
        { resource: "user management", actions: ["read", "write", "delete"] },
        { resource: "roles", actions: ["read", "write", "delete"] },
        { resource: "permissions", actions: ["read", "write", "delete"] },
        {
          resource: "custom-notification",
          actions: ["read", "write", "delete"],
        },
        { resource: "dashboard", actions: ["read"] },
        { resource: "reported-users", actions: ["read"] },
        { resource: "settings", actions: ["read"] },
      ],
    },
    {
      id: 2,
      name: "admin",
      description: "Access to specific panel management",
      permissions: [
        { resource: "user-management", actions: ["read", "write"] },
        { resource: "app-customization", actions: ["read"] },
        { resource: "permissions", actions: ["read"] },
        { resource: "dashboard", actions: ["read"] },
        { resource: "settings", actions: ["read"] },
        { resource: "orders", actions: ["read"] },
        { resource: "Product management", actions: ["read"] },

      ],
    },
    {
      id: 3,
      name: "editor",
      description: "Can edit content but has no administrative privileges",
      permissions: [
        { resource: "posts", actions: ["read", "write", "delete"] },
        { resource: "comments", actions: ["read", "write", "delete"] },
        { resource: "dashboard", actions: ["read"] },
      ],
    },
    {
      id: 4,
      name: "viewer",
      description: "Read-only access to content",
      permissions: [
        { resource: "posts", actions: ["read"] },
        { resource: "comments", actions: ["read"] },
        { resource: "dashboard", actions: ["read"] },
      ],
    },
  ],
};
