import React, { useEffect, useState } from "react";
import { Box, Card, Button, Typography, Stack } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import termCondition from "../../../Assets/termCondition.png";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";

const TermsConditions = () => {
  const dispatch = useDispatch();
  const [editorData, setEditorData] = useState("");

  // ********************Add poicy data  function********************
  const SubmitTermConditionData = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    // console.log("editorData", editorData)
    try {
      const res = await axios.post(
        `/api/auth/adminAuth/add/update/terms/and/conditions`,
        { termsAndConditions: `${editorData}` },
      );
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: "Terms and conditions added successfully",
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(hideLoader());
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "success",
        }),
      );
      console.log("error", error);
    }
  };

  // ********************Get poicy data  function********************
  const getTermConditionData = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.get(
        `/api/auth/adminAuth/get/terms/and/conditions/data`,
      );
      if (res?.data.success) {
        dispatch(hideLoader());
        setEditorData(res?.data.termsAndConditions.termsCondition);
      }
    } catch (error) {
      dispatch(hideLoader());
      if (error.response) {
        // Server responded with a status other than 200 range
        console.error("Response error:", error.response.data);
      } else if (error.request) {
        // Request was made but no response received
        console.error("Request error:", error.request);
      } else {
        // Something else happened in setting up the request
        console.error("Error", error.message);
      }
    }
  };
  // ***************************************************************************************

  // ******************************Stored VAlue inside the state**********************************
  const handleEditorChange = (editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  // *********************************************************************************************
  useEffect(() => {
    getTermConditionData();
  }, []);
  return (
    <Stack>
      <Box>
        <img
          src={termCondition}
          alt="cover page"
          style={{ width: "100%", height: "285px" }}
        />
      </Box>
      <Card
        sx={{ p: 2, px: 4 }}
        component={"form"}
        onSubmit={SubmitTermConditionData}
      >
        <CKEditor
          editor={ClassicEditor}
          // config={editorConfiguration}
          config={
            {
              // ckfinder: {
              //     Upload the images to the server using the CKFinder QuickUpload command
              //     You have to change this address to your server that has the ckfinder php connector
              //     uploadUrl: "/api/v1/admin/save/gurash/saving/image",
              // },
            }
          }
          data={editorData}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => handleEditorChange(editor)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "1rem",
          }}
        >
          <Button
            size="medium"
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              width: "100px",
              "&:hover": {
                backgroundColor: "rgba(82, 118, 10, 1)",
                boxShadow: "none",
                color: "white",
              },
            }}
            disabled={editorData.length === 0}
          >
            Publish
          </Button>
        </Box>
      </Card>
    </Stack>
  );
};

export default TermsConditions;
