import { Box } from "@mui/material";
import SvgColor from "../../../components/svg-color";
import Iconify from "../../../components/iconify/Iconify";

export const newIcon = (iconName) => (
  <Box
    className="box"
    sx={{
      width: 25,
      height: 25,
      borderRadius: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#BDC4CB",
      color: "currentcolor",
    }}
  >
    <Iconify icon={iconName} />
  </Box>
);

export const apeitNowConfig = [
  {
    type: "route",
    title: "dashboard",
    path: "/dashboard/apeitnow",
    // icon: icon("Dashboard"),
    // <iconify-icon icon="fluent:arrow-trending-lines-24-filled"></iconify-icon>
    icon: newIcon("fluent:arrow-trending-lines-24-filled"),
  },
  {
    type: "collapse",
    title: "Product management",
    icon: newIcon("fluent-mdl2:product"),
    path: "/dashboard/product/add-product",
    subRoutes: [
      {
        type: "route",
        name: "add new product",
        key: "product",
        path: "/dashboard/product/add-product",
      },
      {
        type: "route",
        name: "All product",
        key: "product",
        path: "/dashboard/product/all-product",
      },
    ],
  },
  {
    type: "route",
    title: "Orders",
    path: "/dashboard/orders",
    icon: newIcon("fluent:notepad-edit-20-regular"),
  },
  {
    type: "collapse",
    title: "settings",
    // <iconify-icon icon="iconoir:notes"></iconify-icon>
    icon: newIcon("gridicons:customize"),
    path: "/dashboard/settings/seo-setting",
    subRoutes: [
      {
        type: "route",
        name: "seo setting",
        key: "seo-setting",
        path: "/dashboard/settings/seo-setting",
      },
    ],
  },
];
export default apeitNowConfig;
