import ReactDOM from "react-dom/client";

//
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import App from "./App";
import  "./App.css";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./ReduxToolkit/Store/store";
import { SnackbarProvider } from "./ReduxToolkit/Component/SnackbarProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </PersistGate>
  </Provider>
);


serviceWorker.unregister();
reportWebVitals();
