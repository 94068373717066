import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Stack, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import { hideLoader, showLoader } from "../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../reusablecomponents/CustomError/errorMessages";

const Verification = () => {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { email } = location.state || {}; // Destructure the state object
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }

    // The else block is not needed; this runs when timeLeft reaches 0
    setResendDisabled(false);

    // Ensure a consistent return statement (in this case, return undefined)
    return undefined;
  }, [timeLeft]);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  // *********************************************************

  // ***********************  Api OTP Verification Section  ***********************************
  const authentication = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    if (otp === "" || otp.length < 6) {
      dispatch(
        showSnackbar({
          message: `Please enter 6 digit OTP !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    } else {
      try {
        const res = await axios.post(`/api/auth/adminAuth/otp/verification`, {
          otp,
        });
        console.log("otp response", res);
        if (res?.data.success) {
          dispatch(
            showSnackbar({
              message: `User verified successfully`,
              severity: "success",
            }),
          );
          dispatch(hideLoader());
          setOtp("");
          navigate("/resetPassword");
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: `${error?.response.data.message} !`,
            severity: "error",
          }),
        );
        dispatch(hideLoader());
      }
    }
  };
  // for resend otp action
  const resendOtp = async () => {
    dispatch(showLoader());
    setTimeLeft(60);
    setResendDisabled(true);
    try {
      const res = await axios.post(`/api/auth/adminAuth/forgot/password`, {
        email,
      });
      console.log("response", res);
      if (res?.data.success) {
        dispatch(
          showSnackbar({
            message: `OTP has been sent to your account`,
            severity: "success",
          }),
        );
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  // *********************************************************

  // ****************************** hide mail section *******************************
  const maskEmail = (email) => {
    const [localPart, domain] = email.split("@");
    if (localPart.length > 2) {
      return `${localPart.slice(0, 2)}${"*".repeat(
        localPart.length - 2,
      )}@${domain}`;
    }
    return email; // if local part is too short to mask
  };
  // *******************
  return (
    <>
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <Grid
        container
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url("/assets/Background.png")`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          component={"form"}
          onSubmit={authentication}
          item
          xs={11}
          md={6}
          lg={3.5}
          sm={8.5}
          sx={{ bgcolor: "white", borderRadius: "8px" }}
        >
          <Stack
            rowGap={1}
            sx={{
              px: 2.5,
              pt: 6,
              pb: 4,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(51, 51, 51, 1)",
                    fontSize: "25px",
                    fontWeight: 400,
                  }}
                >
                  Enter OTP{" "}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(102, 102, 102, 1)",
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  {" "}
                  We sent a verification code to your Email. Enter the code from
                  the email in the field below.
                </Typography>
              </Box>
              <Box>
                <Typography fontSize="15px" fontWeight="200px">
                  {maskEmail(email)}
                </Typography>
              </Box>
              <Box>
                <Typography fontSize="15px" fontWeight="200px">
                  Type your 6 digit security code
                </Typography>
              </Box>
              <Box pb={3} px={1}>
                <Box fullWidth>
                  <Box>
                    <MuiOtpInput
                      value={otp}
                      required
                      onChange={handleChange}
                      length={6}
                    />
                  </Box>
                  <Box mt={4}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      color="secondary"
                      sx={{
                        borderRadius: "20px",
                        "&:hover": {
                          backgroundColor: "rgba(82, 118, 10, 1)",
                          boxShadow: "none",
                          color: "white",
                        },
                      }}
                    >
                      verify
                    </Button>
                  </Box>
                  <Box mt={3} textAlign="center" sx={{ width: "100%" }}>
                    <Typography color="rgba(121, 116, 126, 1)">
                      Didn’t get the code?
                      {resendDisabled ? (
                        <span style={{ marginLeft: 2, color: "orangered" }}>
                          {timeLeft} sec
                        </span>
                      ) : (
                        <Typography
                          variant="button"
                          color="rgba(175, 175, 175, 1)"
                          fontWeight="medium"
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => resendOtp(email)}
                        >
                          &nbsp; Resend
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Verification;
