import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Paper,
  Slider,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import sound4 from "../../../../Assets/how-long-43048.mp3";
import pause from "../../../../Assets/solar_play-circle-line-duotone.svg";
import play from "../../../../Assets/Group.svg";
import TrashIcon from "../../../../Assets/solar_trash-bin-2-bold-duotone.svg";
import DeleteModal from "../../../../reusablecomponents/DeleteModal/DeleteModal";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";

const AudioPlayer = ({
  data,
  index,
  currentlyPlaying,
  setCurrentlyPlaying,
  getAllsoundTone,
  __id,
}) => {
  const dispatch = useDispatch();
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState("");
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const audioRef = useRef(null);

  // ********************to select current target********************
  useEffect(() => {
    if (currentlyPlaying !== null && playing) {
      audioRef.current.play();
    }
    if (!audioRef.current) {
      // audioRef.current.pause();
      // setPlaying(false);
    } else {
      audioRef.current.addEventListener("timeupdate", () => {
        setCurrentTime(audioRef.current.currentTime);
        setProgress(
          (audioRef.current.currentTime / audioRef.current.duration) * 100,
        );
      });

      audioRef.current.addEventListener("durationchange", () => {
        setDuration(audioRef.current.duration);
      });

      audioRef.current.addEventListener("ended", () => {
        setPlaying(false);
      });
    }

    // return the cleanup function
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", () => {});
        audioRef.current.removeEventListener("durationchange", () => {});
        audioRef.current.removeEventListener("ended", () => {});
      }
    };
  }, [audioRef, playing]);

  const handlePlayPause = () => {
    const mediaElement = document.getElementById(__id);
    if (currentlyPlaying === index) {
      if (playing) {
        mediaElement.pause();
        setPlaying(false);
      } else {
        mediaElement.play();
        setPlaying(true);
      }
    } else {
      if (currentlyPlaying !== null) {
        mediaElement.pause();
      }
      setCurrentlyPlaying(index);
      setPlaying(true);
    }
  };
  const handleSeek = (event, value) => {
    audioRef.current.currentTime = (value / 100) * audioRef.current.duration;
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      buttonText: "",
      data: {
        id: null,
      },
    });
  };
  const deleteSoundTone = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.delete(
        `/api/auth/adminAuth/delete/sound/tone?toneId=${dialog.data.id}`,
      );
      getAllsoundTone();
      if (res?.data.success) {
        dispatch(hideLoader());
        setDialog({
          open: false,
          title: "",
          buttonText: "",
          data: {
            id: null,
          },
        });
        dispatch(
          showSnackbar({
            message: `Sound tone deleted successfully!`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("User updation error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <Grid
      component={Paper}
      elevation={3}
      item
      xs={12}
      md={7}
      sx={{
        p: 2.5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: 2,
      }}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Box
          onClick={handlePlayPause}
          sx={{ cursor: "pointer", display: "flex", alignItems: "start" }}
        >
          {currentlyPlaying === index && playing ? (
            <img
              alt="startButton"
              src={play}
              style={{ width: "28px", height: "28px" }}
            />
          ) : (
            <img
              alt="pauseButton"
              src={pause}
              style={{ width: "28px", height: "28px" }}
            />
          )}
        </Box>
        <Stack justifyContent={"start"} spacing={1}>
          <Typography>{data.toneName}</Typography>
          <audio
            id={__id}
            ref={audioRef}
            src={data?.soundTone.toneUrl}
            type="audio"
          >
            <track kind="captions" />
          </audio>
          <Slider
            value={progress}
            onChange={handleSeek}
            sx={{ width: { xs: "100%", sm: 300 } }}
            aria-labelledby="progress-slider"
            aria-label="Small"
            size="small"
            color="success"
          />
        </Stack>
        <Typography id="progress-slider" variant="body2" sx={{ pt: 3 }}>
          {formatTime(currentTime)} / {formatTime(duration)}
          {/* {formatTime(duration)} */}
        </Typography>
      </Stack>
      <Stack
        alignItems={"center"}
        onClick={() =>
          setDialog({
            open: true,
            title: "tone",
            buttonText: "",
            data: {
              id: data._id,
            },
          })
        }
        sx={{ cursor: "pointer" }}
      >
        <img
          src={TrashIcon}
          alt="trashIcon"
          style={{ width: "35px", height: "35px" }}
        />
        <Typography>Delete</Typography>
      </Stack>
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => deleteSoundTone()}
        />
      )}
    </Grid>
  );
};

AudioPlayer.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  __id: PropTypes.string,
  currentlyPlaying: PropTypes.any,
  setCurrentlyPlaying: PropTypes.func,
  getAllsoundTone: PropTypes.any,
};
export default AudioPlayer;
