import React from "react";
import CircularLoader from "../../../reusablecomponents/CircularLoader/CircularLoader";

const HomePage = () => {
  return (
    <div>
      ApeLabs home
      {/* <CircularLoader /> */}
    </div>
  );
};

export default HomePage;
