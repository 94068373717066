import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";

const RobotsForm = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [isFormChanged, setIsFormChanged] = useState(true);
  const [robotsContent, setRobotsContent] = useState("");
  const [formData, setFormData] = useState({
    useragent: "",
    disallow: "",
    sitemap: "",
  });
  const [disallow, setDisallow] = useState([]);
  const urlPattern = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/sitemap\.xml$/;
  const getRobotsData = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.get(
        "/api/v1/apeitnow/settings/get/file-data?scope=robot",
      );
      setDisallow(res?.data?.disallow);
      setFormData({
        useragent: res?.data.useragent,
        disallow: "",
        sitemap: res?.data?.sitemap,
      });
      setRobotsContent(res?.data);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getRobotsData();
  }, []);

  const handleSubmit = () => {
    const robotsData = { content: robotsContent };
    onSubmit(formData, disallow, getRobotsData);
    setFormData({
      useragent: "",
      disallow: "",
      sitemap: "",
    });
  };
  const handleChange = (e) => {
    setIsFormChanged(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData, // Spread previous state to retain other field values
      [name]: value, // Update only the field that triggered the change
    }));
  };
  const addDisallows = () => {
    setIsFormChanged(true);
    disallow.push(formData.disallow);
  };
  const handleDelete = (id) => {
    const updatedDisallow = [...disallow];

    // Apply the splice method
    updatedDisallow.splice(id, 1);

    // Update the state with the modified array
    setDisallow(updatedDisallow);
    setFormData({ ...formData, disallow: "" });
  };
  return (
    <Stack spacing={2}>
      <Typography variant="h5">Edit robots.txt</Typography>
      <TextField
        variant="outlined"
        name="useragent"
        label="User-agent: *"
        placeholder="User-agent: *"
        value={formData.useragent}
        onChange={(e) => handleChange(e)}
        helperText={
          formData.useragent && !formData.useragent.startsWith("*") ? (
            <span style={{ color: "red" }}>User-agent should start with *</span>
          ) : (
            ""
          )
        }
      />

      <TextField
        name="sitemap"
        value={formData.sitemap}
        label="Sitemap"
        placeholder="https://example.com/sitemap.xml"
        onChange={(e) => handleChange(e)}
        helperText={
          formData.sitemap && !urlPattern.test(formData.sitemap) ? (
            <span style={{ color: "red" }}>
              Sitemap should be like this and start with "https://" Ex:
              https://example.com/sitemap.xml{" "}
            </span>
          ) : (
            ""
          )
        }
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <TextField
          fullWidth
          name="disallow"
          value={formData.disallow}
          label="Disallow"
          placeholder="/admin/"
          onChange={(e) => handleChange(e)}
          sx={{ width: "90%" }}
          helperText={
            formData.disallow && !formData.disallow.startsWith("/") ? (
              <span style={{ color: "red" }}>
                Disallow should start with "/" for Ex: /admin/
              </span>
            ) : (
              ""
            )
          }
        />
        <Button
          variant="outlined"
          disabled={isFormChanged}
          sx={{
            my: 1,
            py: 1,
            "&:hover": {
              backgroundColor: "rgba(82, 118, 10, 1)",
              boxShadow: "none",
              color: "white",
            },
          }}
          startIcon={<AddCircleIcon />}
          onClick={addDisallows}
        >
          Add
        </Button>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        {disallow &&
          disallow?.map((item, id) => (
            <Chip key={id} label={item} onDelete={() => handleDelete(id)} />
          ))}
      </Box>
      {/* <SunEditor
        setContents={
          robotsContent ||
          `User-agent: *
Disallow: /admin/
Sitemap: https://example.com/sitemap.xml
# Add your robots.txt directives here`
        }
        onChange={handleRobotsChange}
        setOptions={{
          height: 200,
          placeholder: `User-agent: *
Disallow: /admin/
Sitemap: https://example.com/sitemap.xml`,
          buttonList: [
            ["undo", "redo"],
            ["codeView"], // Enable code view for plain text editing
          ],
          mode: "classic",
        }}
        height="200px"
      /> */}
      <Button
        variant="contained"
        color="primary"
        sx={{
          "&:hover": {
            backgroundColor: "rgba(82, 118, 10, 1)",
            boxShadow: "none",
            color: "white",
          },
        }}
        onClick={handleSubmit}
      >
        Update robots.txt
      </Button>
    </Stack>
  );
};

RobotsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RobotsForm;
