import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch } from "react-redux";
import ModalComponent from "../../../reusablecomponents/ModalComponent/ModalComponent";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";

const SingleUserPreview = ({ data, handleClose, deleteFunc }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const getSingleUsers = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.get(
        `/api/auth/adminAuth/get/user/data?userId=${data.id}`,
      );
      setUser(res?.data?.User || null);
      if (res?.data.success) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getSingleUsers();
  }, []);
  //  to format the time
  const timeFormater = (date) => {
    const currentTime = dayjs(date);
    const formattedTime = currentTime.format("h:mm A");
    const formatedDate = currentTime.format("DD-MM-YYYY");

    return `${formatedDate.split("-")[2]}`;
  };
  return (
    <ModalComponent open={data.open} handleClose={handleClose}>
      {user === null ? (
        <Stack
          spacing={0.5}
          sx={{
            px: 3,
            py: 1.5,
            width: {
              md: 430,
              sm: 400,
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 500,
          }}
        >
          <Typography>....Loading</Typography>
        </Stack>
      ) : (
        <Stack
          spacing={0.5}
          sx={{
            px: 3,
            py: 1.5,
            width: {
              md: 430,
              sm: 400,
            },
            opacity: user ? 1 : 0, // Transition property
            transition: "opacity 1s ease-in-out", // Transition effect
          }}
        >
          <Stack>
            <Typography sx={{ textAlign: "end" }}>
              Active Since : {timeFormater(user?.createdAt)}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                component={Paper}
                sx={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.25)",
                }}
              >
                <Avatar
                  alt="userProfileImage"
                  src={
                    user.profilePicture ? user.profilePicture.imageUrl : "N/A"
                  }
                  sx={{ width: "90px", height: "105px" }}
                />
              </Box>
            </Box>
          </Stack>
          <Box
            sx={{
              display: "fex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
              {user.name
                ? user.name.charAt(0).toUpperCase() +
                  user.name.slice(1).toLowerCase()
                : "Not Available"}
            </Typography>
          </Box>
          <Box sx={{ display: "fex", justifyContent: "center" }}>
            <Typography sx={{ fontSize: "17px", fontWeight: 400 }}>
              {user.phone}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "fex",
              justifyContent: "center",
              bgcolor: "rgba(97, 177, 90, 1)",
              p: 1,
              borderRadius: "5px",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 400, color: "white" }}
            >{`Total Score : ${
              user.totalScore ? user.totalScore : "N/A"
            }`}</Typography>
          </Box>
          <Box sx={{ display: "fex", justifyContent: "space-between", py: 1 }}>
            <Box
              sx={{
                width: "180px",
                bgcolor: "rgba(173, 206, 116, 1)",
                borderRadius: "5px",
                px: 1.5,
                py: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "white",
                  textAlign: "center",
                }}
              >
                {` Buzz Received : ${
                  user.receivedBuzzUsers ? user.receivedBuzzUsers.length : "0"
                }`}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "180px",
                bgcolor: "rgba(173, 206, 116, 1)",
                borderRadius: "5px",
                px: 1.5,
                py: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "white",
                  textAlign: "center",
                }}
              >
                {` Buzz Sent : ${
                  user.sentBuzzUsers ? user.sentBuzzUsers.length : "0"
                }`}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ color: "rgba(0, 0, 0, 1)" }}>All Badges</Typography>

          <Stack
            columnGap={3}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: {
                md: 400,
                sm: 370,
              },
              overflow: "auto",
              py: 2,
              px: 0.2,
            }}
          >
            {user.badges.length !== 0 ? (
              user.badges.map((badge, index) => {
                return (
                  <Stack
                    spacing={1}
                    key={index}
                    width={135}
                    component={Paper}
                    sx={{
                      borderRadius: "26px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      boxShadow: "0px 0px 10px lightgray",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "rgba(4, 14, 49, 1)",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      {badge.badgeName}
                    </Typography>
                    <Avatar
                      alt="badge Avatar"
                      src={badge.badgeImage.badgeImageUrl}
                      sx={{ width: 80, height: 80 }}
                    />
                    <Typography sx={{ fontSize: "10px", textAlign: "center" }}>
                      {badge.description}
                    </Typography>
                  </Stack>
                );
              })
            ) : (
              <Stack
                width={"95.2%"}
                component={Paper}
                sx={{
                  borderRadius: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 1.5,
                  boxShadow: "0px 0px 10px lightgray",
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  No Achievement
                </Typography>
              </Stack>
            )}
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              bgcolor: "background.paper",
              my: 2,
            }}
          >
            <Button
              sx={{ width: "180px" }}
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color={user.isActive ? "error" : "secondary"}
              sx={{
                width: "180px",
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
              }}
              onClick={() => {
                deleteFunc();
                handleClose();
              }}
            >
              {user.isActive ? "Deactivate" : "Activate"}
            </Button>
          </Box>
        </Stack>
      )}
    </ModalComponent>
  );
};
SingleUserPreview.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  deleteFunc: PropTypes.func,
};
export default SingleUserPreview;
