import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";

// Define your columns
const columns = [
  { id: "orderId", label: "OrderId", minWidth: 150 },
  { id: "orderDate", label: "Order Date", minWidth: 150 },
  { id: "orderItems", label: "Order Items", minWidth: 200 },
  {
    id: "amount",
    label: "Amount",
    minWidth: 150,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "paymentStatus", label: "Payment Status", minWidth: 150 },
  { id: "paymentMethod", label: "Order Type", minWidth: 150 },
  { id: "deliveryStatus", label: "Delivery Status", minWidth: 150 },
  { id: "action", label: "Action", minWidth: 150 },
];

const paymentOptions = ["paid", "unpaid"];
const deliveryOptions = [
  "pending",
  "received",
  "in progress",
  "packaged",
  "shipped",
  "delivered",
  "canceled",
];

const OrderTable = ({
  rows,
  page,
  rowsPerPage,
  total,
  onPageChange,
  onRowsPerPageChange,
  handleView, // Props for handling the actions
  handleDelete,
  handlePaymentStatusChange, // Function to handle payment status change
  handleDeliveryStatusChange, // Function to handle delivery status change
}) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === "action" ? (
                          <div>
                            {/* Action buttons */}
                            <IconButton onClick={() => handleView(row.orderId)}>
                              <Visibility />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(row.orderId)}
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        ) : column.id === "paymentStatus" ? (
                          // Dropdown for paymentStatus
                          <TextField
                            select
                            size="small"
                            value={row?.paymentStatus?.toLowerCase()}
                            onChange={(e) =>
                              handlePaymentStatusChange(
                                row.orderId,
                                e.target.value
                              )
                            }
                            fullWidth
                          >
                            {paymentOptions.map((option) => (
                              <MenuItem
                                key={option}
                                sx={{ textTransform: "capitalize" }}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : column.id === "deliveryStatus" ? (
                          // Dropdown for deliveryStatus
                          <TextField
                            select
                            size="small"
                            value={row.deliveryStatus.toLowerCase()}
                            onChange={(e) =>
                              handleDeliveryStatusChange(
                                row.orderId,
                                e.target.value
                              )
                            }
                            fullWidth
                          >
                            {deliveryOptions.map((option) => (
                              <MenuItem
                                key={option}
                                value={option.toLocaleLowerCase()}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableRow>
      </TableFooter>
    </Paper>
  );
};

export default OrderTable;
