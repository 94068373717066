import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import CustomSelect from "./CustomeSelect";

const ProductTypeInput = ({
  selectedType,
  colorOption,
  selectedColor,
  setSelectedColor,
  data,
  selectedAttr,
  changeAttr,
  attrItemOption,
  attrItemList,
  setAttrItemList,
  variantInputList,
  handleInputChange,
  galleryImage,
}) => {
  //   const { t } = useTranslation();

  return (
    <Box>
      {selectedType === "simple" && (
        <Card sx={{ mb: 5, boxShadow: 2, borderRadius: 2 }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                component="div"
                sx={{ fontWeight: "bold" }}
              >
                {"Product Information"}
              </Typography>
            }
          />
          <Divider />
          <CardContent>
            <Box>
              <FormControl fullWidth sx={{ py: 3 }}>
                <FormLabel htmlFor="inp-6">
                  {"Item Quantity"}*({"Set -1 to make it unlimited"})
                </FormLabel>
                <TextField
                  type="number"
                  inputProps={{ min: -1 }}
                  id="inp-6"
                  name="qty"
                  defaultValue={1}
                  required
                  fullWidth
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </FormControl>
              <FormControl fullWidth sx={{ py: 3 }}>
                <FormLabel>{"sku"}*</FormLabel>
                <TextField type="text" name="sku" required fullWidth />
              </FormControl>
            </Box>
          </CardContent>
        </Card>
      )}
      {selectedType === "variable" && (
        <Card sx={{ mb: 5, boxShadow: 2, borderRadius: 2 }}>
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {"Product Variation"}
              </Typography>
            }
            sx={{ bgcolor: "background.paper", py: 2 }}
          />
          <Divider />
          <CardContent>
            <Box sx={{ py: 3 }}>
              <FormLabel>{"Colors"}</FormLabel>
              <Autocomplete
                size="small"
                multiple
                id="colors-autocomplete"
                options={colorOption}
                value={selectedColor}
                onChange={(event, newValue) => setSelectedColor(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label={"Select Colors"}
                    placeholder={"Select Colors"}
                  />
                )}
              />
            </Box>
            <Box sx={{ py: 3 }}>
              <FormLabel>{"Attributes"}</FormLabel>
              <Select
                fullWidth
                defaultValue=""
                size="small"
                onChange={(evt) => changeAttr(evt.target.value)}
              >
                <MenuItem value="" disabled>
                  {"Select Attribute"}
                </MenuItem>
                {data?.attribute?.map((attr, idx) => (
                  <MenuItem value={idx} key={idx}>
                    {attr?.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {selectedAttr >= 0 && (
              <Box sx={{ py: 3 }}>
                <FormLabel>{data.attribute[selectedAttr].name}</FormLabel>
                <Autocomplete
                  multiple
                  id="attribute-items-autocomplete"
                  options={attrItemOption(selectedAttr)}
                  value={attrItemList}
                  onChange={(event, newValue) => setAttrItemList(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      //   label={"Select Attribute Items"}
                      placeholder={"Select Attribute Items"}
                    />
                  )}
                />
              </Box>
            )}
            {variantInputList.length > 0 &&
              variantInputList.map((variant, index) => (
                <Box key={index}>
                  <hr />
                  <Typography variant="h6">
                    {"Variant"}:{" "}
                    {`${variant.color ? variant.color : ""} ${
                      variant.color && variant.attr ? "+" : ""
                    } ${variant.attr ? variant.attr : ""}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 2,
                    }}
                  >
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Additional Price"}*</FormLabel>
                      <TextField
                        type="number"
                        inputProps={{ min: 0, step: 0.01 }}
                        name="price"
                        required
                        fullWidth
                        value={variant.price || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Set 0 to make it free"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"sku"}*</FormLabel>
                      <TextField
                        type="text"
                        name="sku"
                        required
                        fullWidth
                        value={variant.sku || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                      />
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Item Quantity"}*</FormLabel>
                      <TextField
                        type="number"
                        inputProps={{ min: -1 }}
                        name="qty"
                        required
                        fullWidth
                        value={variant.qty || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Set -1 to make it unlimited"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 3 }}>
                      <FormLabel>{"Barcode"}*</FormLabel>
                      <TextField
                        type="string"
                        inputProps={{ min: -1 }}
                        name="barcode"
                        required
                        fullWidth
                        value={variant.barcode || ""}
                        onChange={(evt) => handleInputChange(evt, index)}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                      <Typography variant="caption" color="primary">
                        {"Set -1 to make it unlimited"}
                      </Typography>
                    </Box>
                    <Box sx={{ flex: 1, minWidth: "250px", py: 2 }}>
                      <CustomSelect
                        list={galleryImage || []}
                        dataChange={handleInputChange}
                        rootIndex={index}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default ProductTypeInput;
