import React, { useState, useEffect } from "react";
import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
  InputLabel,
  FormControl,
  Input,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import UploadIcon from "../../../../Assets/solar_upload-line-duotone.png";
import ModalComponent from "../../../../reusablecomponents/ModalComponent/ModalComponent";
import editIcon from "../../../../Assets/editIcon.svg";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";

const UpdateBadge = ({ update, handleClose, getAllbadge }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [FileUrl, setFileUrl] = useState(null);
  const [title, setTitle] = useState("");
  const [badges, setbadges] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(true);

  const geSinglebadge = async (e) => {
    try {
      const res = await axios.get(
        `/api/auth/adminAuth/get/single/badge?badgeId=${update.id}`,
      );
      setbadges(res?.data.data);
      setFileUrl(res?.data.data.badgeImage.badgeImageUrl);
      setTitle(res?.data.data.badgeName);
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
      } else if (error.request) {
        console.error("Request error:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  useEffect(() => {
    geSinglebadge();
  }, []);
  const handleUpload = (e) => {
    setIsFormChanged(false);
    const file = e.target.files[0];

    // Check if the file is an image
    if (file && file.type.startsWith("image/")) {
      setFileUrl(URL.createObjectURL(file));
      setFile(file);
    } else {
      dispatch(
        showSnackbar({ message: `Only images are allow !`, severity: "error" }),
      );
    }
  };
  const resetHandler = () => {
    geSinglebadge();
    setIsFormChanged(true);
  };
  const updateBadgeHandler = async (e) => {
    e.preventDefault();
    setIsFormChanged(true);
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("file", file);
    formData.append("badgeName", title);
    try {
      const res = await axios.put(
        `/api/auth/adminAuth/update/badge?badgeId=${badges._id}`,
        formData,
      );
      if (res?.data.success) {
        dispatch(
          showSnackbar({
            message: "Badge updated successfully!",
            severity: "success",
          }),
        );
        getAllbadge();
        dispatch(hideLoader());
        handleClose();
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <ModalComponent open={update.open} handleClose={handleClose}>
      <Grid container rowGap={3}>
        <Grid
          item
          xs={12}
          component={"form"}
          onSubmit={updateBadgeHandler}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: 3,
          }}
        >
          <Stack spacing={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {FileUrl && (
                <Stack position={"relative"}>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "rgb(123,128,154,0.3)",
                      // p:2
                    }}
                  >
                    <Box
                      variant="h5"
                      gutterBottom
                      sx={{
                        width: "95px",
                        height: "95px",
                        borderRadius: "50%",
                        background: "transparent",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "white",
                        // pt:1.5
                      }}
                    >
                      <img
                        src={FileUrl}
                        alt="logo"
                        accept="image/*"
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 26,
                      bottom: 5,
                      width: "22px",
                      height: "22px",
                      borderRadius: "50%",
                      background: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl>
                      <Input
                        type="file"
                        accept="image/*"
                        sx={{ display: "none" }}
                        id="fileInput"
                        onChange={handleUpload}
                      />
                    </FormControl>
                    <InputLabel htmlFor="fileInput">
                      <img
                        alt="edit"
                        style={{ height: "14px", width: "14px" }}
                        src={editIcon}
                      />
                    </InputLabel>
                  </Box>
                </Stack>
              )}
            </Box>
            <Stack>
              <TextField
                fullWidth
                placeholder="Write badge name"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setIsFormChanged(false);
                }}
                variant="outlined"
                required
                label="Badge Name"
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  cursor: "pointer",
                  pt: 0.5,
                }}
                onClick={resetHandler}
              >
                <RestartAltIcon sx={{ width: "15px", height: "15px" }} />
                <Typography sx={{ fontSize: "12px" }}>Reset</Typography>
              </Box>
            </Stack>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                size="medium"
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isFormChanged}
                sx={{
                  width: "100px",
                  "&:hover": {
                    backgroundColor: "rgba(82, 118, 10, 1)",
                    boxShadow: "none",
                    color: "white",
                  },
                }}
              >
                save
              </Button>
              <Button
                size="medium"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
                sx={{
                  width: "100px",
                  // "&:hover": {
                  //     backgroundColor: "rgba(82, 118, 10, 1)",
                  //     boxShadow: "none",
                  //     color: "white",
                  // },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};
UpdateBadge.propTypes = {
  update: PropTypes.object,
  handleClose: PropTypes.func,
  getAllbadge: PropTypes.func,
};
export default UpdateBadge;
