import {
  Badge,
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Dummy Data
const orderData = {
  orderId: "123456",
  paymentId: "abcd1234",
  orderDate: new Date().toISOString(),
  paymentStatus: "Paid",
  status: "Shipped",
  paymentMethod: "Credit Card",
  coupon: { code: "SUMMER21", discount: 15 },
  deliveryInfo: {
    type: "Standard",
    area: "Downtown",
    cost: 5.99,
  },
  billingInfo: {
    fullName: "John Doe",
    phone: "555-1234",
    email: "john.doe@example.com",
    house: "123 Elm St",
    city: "Springfield",
    state: "IL",
    zipCode: "62701",
    country: "USA",
  },
  shippingInfo: {
    fullName: "Jane Doe",
    phone: "555-5678",
    email: "jane.doe@example.com",
    house: "456 Oak St",
    city: "Springfield",
    state: "IL",
    zipCode: "62701",
    country: "USA",
  },
  products: [
    {
      name: "Product 1",
      image: [{ url: "https://via.placeholder.com/50" }],
      sku: "P001",
      color: { name: "Red" },
      attribute: { for: "Size", name: "M" },
      qty: 1,
      price: 29.99,
      subTotal: 29.99 * 1,
    },
    {
      name: "Product 2",
      image: [{ url: "https://via.placeholder.com/50" }],
      sku: "P002",
      color: { name: "Blue" },
      attribute: { for: "Size", name: "L" },
      qty: 2,
      price: 49.99,
      subTotal: 49.99 * 2,
    },
  ],
  totalPrice: 79.98,
  vat: 7.99,
  tax: 3.5,
  payAmount: 91.47,
};

const SingleOrderScreen = () => {
  const [loading, setLoading] = useState(false);
  const { orderId } = useParams();
  const [orderInfo, setOrderInfo] = useState(null);
  const currencySymbol = "₹";
  const navigate = useNavigate();
  const dateFormat = (date) => new Date(date).toLocaleDateString();

  const decimalBalance = (amount) => amount;

  const checkPercentage = (total, percentage) => total * (percentage / 100);

  const handleGetOrderDetail = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/v1/apeitnow/orders/track/order/${orderId}`
      );
      console.log("Data==>", response.data);
      setOrderInfo(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    if (orderId) {
      handleGetOrderDetail();
    }

    return () => {
      setOrderInfo(null);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Box sx={{ width: "100%", margin: "auto" }} mt={4}>
          <Skeleton animation="wave" sx={{ height: 100 }} />
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ height: 200 }}
          />
          <Skeleton animation="wave" sx={{ height: 100 }} />
        </Box>
      ) : (
        <Box component={Paper} bgcolor={"transparent"} border="1px solid red">
          <Paper sx={{ p: 2 }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h5" component={"p"}>
                Order Details
              </Typography>
              <Box
                columnGap={1}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <Button variant="outlined" color="info">
                  Print
                </Button>
              </Box>
            </Stack>
          </Paper>
          <Box p={3}>
            {orderInfo?._id && (
              <div>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <Typography variant="h6">Order Details:</Typography>
                    {orderInfo?.paymentId && (
                      <Typography>
                        Transaction Id: <span>{orderInfo.paymentId}</span>
                      </Typography>
                    )}
                    <Typography>
                      Order ID: <span>{orderInfo?._id}</span>
                    </Typography>
                    <Typography>
                      Order Date:{" "}
                      <span>{dateFormat(orderInfo?.orderDate)}</span>
                    </Typography>
                    <Typography>
                      Payment Status:{" "}
                      <Badge
                        sx={{
                          bgcolor:
                            orderInfo?.paymentStatus &&
                            typeof orderInfo.paymentStatus === "string" &&
                            orderInfo.paymentStatus.toLowerCase() === "paid"
                              ? "green"
                              : "red",
                          color: "white",
                          px: 0.2,
                          py: 0.05,
                        }}
                        // color={
                        //   orderInfo?.paymentStatus === "Unpaid"
                        //     ? "error"
                        //     : "success"
                        // }
                      >
                        {orderInfo?.paymentStatus}
                      </Badge>
                    </Typography>
                    <Typography>
                      Order Status:{" "}
                      <Badge color="primary">{orderInfo?.status}</Badge>
                    </Typography>
                    <Typography>
                      Payment Method: <span>{orderInfo?.paymentMethod}</span>
                    </Typography>
                    {orderInfo?.coupon?.code && (
                      <Typography>
                        Applied Coupon:{" "}
                        <span>{`${orderInfo?.coupon?.code} - ${orderInfo?.coupon?.discount}%`}</span>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6">Delivery Information:</Typography>
                    {/* <Typography>
                  Delivery Type: <span>{orderInfo?.deliveryInfo?.type}</span>
                </Typography> */}
                    {orderInfo?.deliveryInfo?.area && (
                      <Typography>
                        Delivery Area:{" "}
                        <span>{orderInfo?.deliveryInfo?.area}</span>
                      </Typography>
                    )}
                    <Typography>
                      Delivery Cost:{" "}
                      {/* <span>{currencySymbol + orderInfo?.deliveryInfo?.cost}</span> */}
                      <span>Free</span>
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6">Billing Address:</Typography>
                    <Typography>
                      Full Name: <span>{orderInfo?.billingInfo?.name}</span>
                    </Typography>
                    <Typography>
                      Phone: <span>{orderInfo?.billingInfo?.phone}</span>
                    </Typography>
                    <Typography>
                      Email: <span>{orderInfo?.billingInfo?.email}</span>
                    </Typography>
                    <Typography>
                      House: <span>{orderInfo?.billingInfo?.house}</span>
                    </Typography>
                    <Typography>
                      City: <span>{orderInfo?.billingInfo?.city}</span>
                    </Typography>
                    <Typography>
                      State/Province:{" "}
                      <span>{orderInfo?.billingInfo?.state}</span>
                    </Typography>
                    <Typography>
                      Post/Zip Code:{" "}
                      <span>{orderInfo?.billingInfo?.zipCode}</span>
                    </Typography>
                    <Typography>
                      Country: <span>{orderInfo?.billingInfo?.country}</span>
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="h6">Shipping Address:</Typography>
                    <Typography>
                      Full Name: <span>{orderInfo?.shippingInfo?.name}</span>
                    </Typography>
                    <Typography>
                      Phone: <span>{orderInfo?.shippingInfo?.phone}</span>
                    </Typography>
                    <Typography>
                      Email: <span>{orderInfo?.shippingInfo?.email}</span>
                    </Typography>
                    <Typography>
                      House: <span>{orderInfo?.shippingInfo?.house}</span>
                    </Typography>
                    <Typography>
                      City: <span>{orderInfo?.shippingInfo?.city}</span>
                    </Typography>
                    <Typography>
                      State/Province:{" "}
                      <span>{orderInfo?.shippingInfo?.state}</span>
                    </Typography>
                    <Typography>
                      Post/Zip Code:{" "}
                      <span>{orderInfo?.shippingInfo?.zipCode}</span>
                    </Typography>
                    <Typography>
                      Country: <span>{orderInfo?.shippingInfo?.country}</span>
                    </Typography>
                  </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Products</TableCell>
                        <TableCell>Image</TableCell>
                        {/* <TableCell>SKU</TableCell> */}
                        {/* <TableCell>Color</TableCell> */}
                        {/* <TableCell>Attributes</TableCell> */}
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Sub Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderInfo?.products?.map((product, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{product?.productName}</TableCell>
                          <TableCell>
                            <img
                              src={`${product?.product?.image[0]?.url}`}
                              width={50}
                              height={50}
                              alt={product?.productName}
                            />
                          </TableCell>
                          {/* <TableCell>{product?.sku}</TableCell> */}
                          {/* <TableCell>{product.color.name || "-"}</TableCell> */}
                          {/* <TableCell>
                        {product.attribute.name
                          ? `${product.attribute.for} : ${product.attribute.name}`
                          : "-"}
                      </TableCell> */}
                          <TableCell>{product?.quantity}</TableCell>
                          <TableCell>
                            {currencySymbol + product?.price}
                          </TableCell>
                          <TableCell>{product?.total}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box mt={3}>
                  <Typography variant="h6">Order Summary:</Typography>
                  <Typography>
                    Sub Total:{" "}
                    <span>{currencySymbol + orderInfo?.totalPrice}</span>
                  </Typography>
                  <Typography>
                    Discount:{" "}
                    <span>
                      {currencySymbol +
                        decimalBalance(
                          checkPercentage(
                            orderInfo?.totalPrice,
                            orderInfo?.coupon?.discount || 0
                          )
                        )}
                    </span>
                  </Typography>
                  <Typography>
                    Delivery Charge:{" "}
                    {/* <span>{currencySymbol + orderInfo?.deliveryInfo?.cost}</span> */}
                    <span>0</span>
                  </Typography>
                  <Typography>
                    VAT: <span>{currencySymbol + (orderInfo?.vat || 0)}</span>
                  </Typography>
                  <Typography>
                    Tax: <span>{currencySymbol + (orderInfo?.tax || 0)}</span>
                  </Typography>
                  <Typography>
                    Total:{" "}
                    <span>
                      {currencySymbol + decimalBalance(orderInfo?.payAmount)}
                    </span>
                  </Typography>
                </Box>
              </div>
            )}
            <div
              id="inv_content"
              style={{
                minHeight: "max-content",
                display: "none",
              }}
            >
              {/* {orderData.orderId && <InvoicePrint data={orderData} />} */}
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SingleOrderScreen;
