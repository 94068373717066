import { Box, Grid, Typography } from "@mui/material";
import React, { ReactElement } from "react";


const PanelContainer = ({
  pageHeading,
  children,
}) => {
  return (
    <>
      <Box width={"100%"}>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            {pageHeading ? (
              <Typography sx={{ fontWeight: 600, mt: 4, ml: 4 }}>
                {pageHeading}
              </Typography>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PanelContainer;
