import { Helmet } from "react-helmet-async";
import { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { hideLoader, showLoader } from "../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../reusablecomponents/CustomError/errorMessages";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { email } = location.state || {};

 
  const handleChange = (type, e) => {
    if (type === "password") {
      setPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value);
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const authentication = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    try {
      const res = await axios.patch(`/api/auth/adminAuth/reset/password`, {
        newPassword: password,
        confirmNewPassword: confirmPassword,
      });
      console.log("response", res);
      if (res?.data.success) {
        dispatch(
          showSnackbar({
            message: `Password has been changed successfully !`,
            severity: "success",
          }),
        );
        dispatch(hideLoader());
        navigate("/login");
      }
    } catch (error) {
      console.log("error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <Grid
        container
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url("/assets/Background.png")`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          component={"form"}
          onSubmit={authentication}
          item
          xs={11}
          md={6}
          lg={3.5}
          sm={8.5}
          sx={{ bgcolor: "white", borderRadius: "8px" }}
        >
          <Stack
            rowGap={1}
            sx={{
              px: 3,
              py: 6,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(51, 51, 51, 1)",
                    fontSize: "25px",
                    fontWeight: 400,
                  }}
                >
                  {" "}
                  Reset Password{" "}
                </Typography>
              </Box>
              <Stack spacing={1}>
                <Typography sx={{ color: "rgba(102, 102, 102, 1)" }}>
                  New Password
                </Typography>
                <TextField
                  size="medium"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => handleChange("password", e)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={{ color: "rgba(102, 102, 102, 1)" }}>
                  Confirm Password
                </Typography>
                <TextField
                  size="medium"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => handleChange("confirmPassword", e)}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Stack>
            </Stack>
            <span style={{ color: "red" }}>
              {confirmPassword.length !== 0 &&
                password !== confirmPassword &&
                "password and confirm password are not same"}
            </span>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
                mt: "10px",
              }}
            >
              Reset Password
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
