// component
import { Box } from "@mui/material";
import SvgColor from "../../../components/svg-color";
import Iconify from "../../../components/iconify/Iconify";

export const newIcon = (iconName) => (
  <Box
    className="box"
    sx={{
      width: 25,
      height: 25,
      borderRadius: "0.25rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#BDC4CB",
      color: "currentcolor",
    }}
  >
    <Iconify icon={iconName} />
  </Box>
);

export const icon = (name) => (
  // <img
  //   style={{ width: 25, height: 25 }}
  //   src={`/assets/icons/navbar/${name}.svg`}
  //   alt="sidebarIcon"
  // />

  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{
      width: 1,
      height: 1,
      bgcolor: "rgba(207, 237, 204, 1)",
      color: "rgba(97, 177, 90, 1)", // Set icon color
    }}
  />
);

export const navConfig = [
  {
    type: "route",
    title: "dashboard",
    path: "/dashboard/home",
    // icon: icon("Dashboard"),
    // <iconify-icon icon="fluent:arrow-trending-lines-24-filled"></iconify-icon>
    icon: newIcon("fluent:arrow-trending-lines-24-filled"),
  },
  {
    type: "route",
    title: "user management",
    path: "/dashboard/user-management",
    icon: newIcon("ph:user-fill"),
  },
  {
    type: "collapse",
    title: "App Customization",
    icon: newIcon("gridicons:customize"),
    path: "/dashboard/app-customization/app-ambassador",
    subRoutes: [
      {
        type: "route",
        name: "app ambassador",
        key: "app-ambassador",
        path: "/dashboard/app-customization/app-ambassador",
      },
      {
        type: "route",
        name: "manage avatar",
        key: "manage-avatar",
        path: "/dashboard/app-customization/manage-avatar",
      },
      {
        type: "route",
        name: "manage badge",
        key: "manage-badge",
        path: "/dashboard/app-customization/manage-badge",
      },
      {
        type: "route",
        name: "sound tone",
        key: "sound-tone",
        path: "/dashboard/app-customization/sound-tone",
      },
    ],
  },
  {
    type: "collapse",
    title: "CMS",
    icon: newIcon("iconoir:notes"),
    path: "/dashboard/cms/privacy-policy",
    subRoutes: [
      {
        type: "route",
        name: "privacy policy",
        key: "privacy-policy",
        path: "/dashboard/cms/privacy-policy",
      },
      {
        type: "route",
        name: "terms conditions",
        key: "Terms_&_Conditions ",
        path: "/dashboard/cms/terms-conditions",
      },
      {
        type: "route",
        name: "feedback",
        key: "manage-badge",
        path: "/dashboard/cms/feedback",
      },
    ],
  },
  {
    type: "route",
    title: "Reported Users",
    path: "/dashboard/reported-users",
    icon: newIcon("ic:outline-report"),
  },
  {
    type: "route",
    title: "Custom Notification",
    path: "/dashboard/custom-notification",
    icon: newIcon("mage:notification-bell-download"),
  },
];

export default navConfig;
