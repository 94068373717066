import React, { useState, useEffect, useMemo } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";

const AppStats = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const endpoints = [
        "/api/auth/adminAuth/get/users/stats",
        "/api/auth/adminAuth/get/new/users/stats",
        "/api/auth/adminAuth/get/buzz/stats",
        "/api/auth/adminAuth/get/reported/users/stats",
      ];

      try {
        // Fetch data from all endpoints concurrently
        const responses = await axios.all(
          endpoints.map((endpoint) => axios.get(endpoint)),
        );

        // Map responses to a stats object
        const stats = responses.reduce((acc, response) => {
          switch (response.config.url) {
            case "/api/auth/adminAuth/get/users/stats":
              acc.allUsers = response.data;
              break;
            case "/api/auth/adminAuth/get/new/users/stats":
              acc.newUsers = response.data;
              break;
            case "/api/auth/adminAuth/get/buzz/stats":
              acc.totalBuzz = response.data;
              break;
            case "/api/auth/adminAuth/get/reported/users/stats":
              acc.bugReports = response.data;
              break;
            default:
              break;
          }
          return acc;
        }, {});

        console.log("Fetched stats:", stats);
        setStats(stats);
      } catch (error) {
        console.error("Error fetching data:", error);
        dispatch(hideLoader());
      }
    };

    fetchData(); // Call the fetch function
  }, [dispatch]);

  const memoizedStats = useMemo(() => stats, [stats]);
  // console.log("memoizedStats", memoizedStats);
  // console.log("stats", stats);

  return (
    <>
      {memoizedStats === null ? (
        <Grid container columnGap={2} rowGap={2} ml={3}>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            No records found
          </Grid>
        </Grid>
      ) : (
        <Grid container columnGap={2} rowGap={2} ml={3}>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {stats?.allUsers?.totalUsers.length > 1000
                  ? `${(memoizedStats?.allUsers?.totalUsers.length / 1000).toFixed(
                      1,
                    )}k`
                  : memoizedStats?.allUsers?.totalUsers.length}
              </Typography>
              <Typography>All Users</Typography>
            </Stack>
          </Grid>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {memoizedStats?.newUsers?.newUsers > 1000
                  ? `${(memoizedStats?.newUsers?.newUsers / 1000).toFixed(1)}k`
                  : memoizedStats?.newUsers?.newUsers}
              </Typography>
              <Typography>New Users</Typography>
            </Stack>
          </Grid>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {memoizedStats?.totalBuzz?.totalBuzz > 1000
                  ? `${(memoizedStats?.totalBuzz?.totalBuzz / 1000).toFixed(
                      1,
                    )}k`
                  : memoizedStats?.totalBuzz?.totalBuzz}
              </Typography>
              <Typography>Total Buzz</Typography>
            </Stack>
          </Grid>
          <Grid
            component={Paper}
            elevation={2}
            item
            xs={5.6}
            md={2.85}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 2.5,
            }}
          >
            <Stack sx={{ textAlign: "center" }}>
              <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                {memoizedStats?.bugReports?.totalReports > 1000
                  ? `${(memoizedStats?.bugReports?.totalReports / 1000).toFixed(
                      1,
                    )}k`
                  : memoizedStats?.bugReports?.totalReports}
              </Typography>
              <Typography>Total Reports</Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AppStats;
