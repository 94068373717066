import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
//
import axios from "axios";
import Header from "./header";
import Nav from "./nav";
import { hideLoader, showLoader } from "../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../ReduxToolkit/Slices/snackbarSlice";
import Breadcrumbs from "../../pages/Breadcrumbs";
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  backgroundColor: "#F0F2F5",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const auth = useSelector((state) => state.auth.isAuthenticated);
  const selectedPanel = useSelector((state) => state.panel.selectedPanel);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAuth = async () => {
    // dispatch(showLoader());
    try {
      const res = await axios.get("/api/auth/adminAuth/authentication");
      // console.log("authres", res);
      if (res?.data.success) {
        dispatch(hideLoader());
        navigate("/dashboard/home");
      } else {
        dispatch(
          showSnackbar({
            message: `You are Unauthorized Please login!`,
            severity: "error",
          }),
        );
        navigate("/login");
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `You are ${error?.response.data.message} Please login!`,
          severity: "error",
        }),
      );
      navigate("/login");
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    if (!auth) {
      handleAuth();
    }
  }, []);

  return (
    <>
      <StyledRoot>
        <Header onOpenNav={() => setOpen(true)} />

        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main>
          <Box mt={-3} pb={6} px={3}>
            <Breadcrumbs />
          </Box>
          <Box
            sx={{
              px: {
                xs: 3,
                sm: 3,
                md: pathname === "/dashboard/home" ? 1 : 3,
              },
            }}
          >
            <Outlet />
          </Box>
        </Main>
      </StyledRoot>
    </>
  );
}
