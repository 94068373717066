// import React, { useState } from "react";

// const REACT_APP_PUBLIC_URL = "http://localhost:5001";

import { Box } from "@mui/material";
import React, { useState } from "react";

const ImageLoader = ({
  src,
  alt,
  width = 100,
  height = 100,
  className,
  style,
  id,
  mouseMove,
  mouseOut,
  click,
}) => {
  const [imgSrc, setImgSrc] = useState(src);
  const placeholder = "/path/to/placeholder.jpg"; // Define your placeholder image path

  return (
    <Box
      src={imgSrc}
      component={"img"}
      alt={alt || src || ""}
      width={width}
      height={height}
      className={className}
      style={style}
      id={id}
      // onMouseMove={mouseMove}
      // onMouseOut={mouseOut}
      onClick={click}
      onLoad={(e) => {
        if (e.target.naturalWidth === 0) {
          setImgSrc(placeholder);
        }
      }}
      onError={() => {
        setImgSrc(placeholder);
      }}
      role="button" // Added for accessibility
      tabIndex={0} // Make the element focusable
      // onKeyDown={(e) => {
      //   if (e?.key === "Enter") {
      //     click && click(e);
      //   }
      // }}
    />
  );
};

export default ImageLoader;
