import React from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import DeleteIconNew from "../../../Assets/GroupDelete.png";
import changeIcon from '../../../Assets/gg_profile.svg'
import ModalComponent from "../../../reusablecomponents/ModalComponent/ModalComponent";
import editIcon from '../../../Assets/editIcon.svg'


const ChangleProfileModal = ({ open, handleClose, dialog, deleteFunc, Icon }) => {
    return (
        <>
            <ModalComponent open={open} handleClose={handleClose} type={"delete"}>
                <Stack
                    spacing={1}
                    sx={{
                        // bgcolor: "background.paper",
                        display: "flex",
                        flexDirection: "column",
                        p:2
                    }}
                >
                    {/* <img src={DeleteIconNew} /> */}
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Stack mt={1} mb={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                            {!Icon ? (
                                <>
                                    <img
                                        alt="edit"
                                        style={{ height: "35px", width: "35px" }}
                                        src={editIcon}
                                    />
                                </>

                            ) : (
                                Icon
                            )}
                        </Stack>
                    </Box>
                    <Typography
                        sx={{
                            color: "black",
                            fontSize: "17px",
                        }}
                    >
                        {dialog.buttonText === ""
                            ? `Are you sure you want to delete this ${dialog?.title} ${dialog?.message} ?`
                            : `${dialog?.title} ${dialog?.message}`}
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            bgcolor: "background.paper",
                            my: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            size="medium"
                            sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(82, 118, 10, 1)",
                                  boxShadow: "none",
                                  color: "white",
                                },
                              }}
                            onClick={() => {
                                deleteFunc();
                                handleClose();
                            }}
                        >
                            {dialog.buttonText ? dialog.buttonText : "Delete"}
                            {/* delete */}
                        </Button>
                        <Button variant="outlined" color="secondary" size="medium" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </Stack>
            </ModalComponent>
        </>
    );
}
ChangleProfileModal.propTypes = {
    open: PropTypes.bool,
    children: PropTypes.node,
    dialog: PropTypes.object,
    handleClose: PropTypes.func,
    deleteFunc: PropTypes.func,
    Icon: PropTypes.element,
};
export default ChangleProfileModal