import React, { useState, useEffect } from "react";
import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import AllQuote from "./AllQuote";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontSize: "16px",
    padding: "8px",
  },
  "& .MuiInputLabel-root": {
    fontSize: "20px", // Adjust the font size of the label
    // marginLeft: '10px', // Adjust the margin to prevent overlapping
    backgroundColor: "#F0F2F5", // Set background color to avoid overlap with the border
    padding: "0 35px", // Padding to add space on both sides
    transform: "translate(14px, -12px) scale(1)", // Adjust transform to position the label correctly
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "gray", // Custom border color
    },
    "&:hover fieldset": {
      borderColor: "blue", // Custom border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "green", // Custom border color when focused
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -20px) scale(0.75)", // Adjust transform when label shrinks
    },
  },
}));

const AppAmbassador = () => {
  const dispatch = useDispatch();
  const [quote, setQuote] = useState("");
  const [allQuote, setAllQuote] = useState(null);
  const getAllQuotes = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.get("/api/auth/adminAuth/get/all/quotes");
      setAllQuote(res?.data.allQuotes);
      if (res?.data.success) {
        dispatch(hideLoader());
      }
    } catch (error) {
      console.log("App Ambasder error", error);
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllQuotes();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(showLoader());
    try {
      const res = await axios.post("/api/auth/adminAuth/add/quote", {
        newQuote: quote,
      });
      if (res?.data.success) {
        setQuote("");
        getAllQuotes();
        dispatch(hideLoader());
        dispatch(
          showSnackbar({
            message: `Quote added successfully !`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      console.log("App Ambasder error", error);
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={11.8}>
          <Stack spacing={2} component={"form"} onSubmit={submitHandler}>
            <CustomTextField
              fullWidth
              placeholder="Write quote here..."
              id="title"
              value={quote}
              required
              onChange={(e) => setQuote(e.target.value)}
              variant="outlined"
              multiline
              label="Create Quote"
              rows={2}
              inputProps={{
                maxLength: 150,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment style={{ fontSize: "5px" }} position="end">
                    <Typography fontSize="12px">{`${quote.length}/150`}</Typography>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField multiline minRows={5} label="Create Quote" focused /> */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  width: "160px",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(82, 118, 10, 1)",
                    boxShadow: "none",
                    color: "white",
                  },
                }}
              >
                Add
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <AllQuote quote={allQuote} getAllQuotes={getAllQuotes} />
    </>
  );
};

export default AppAmbassador;
