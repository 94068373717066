import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Avatar,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import {
  hideLoader,
  showLoader,
} from "../../../ReduxToolkit/Slices/loaderSlice";
import PendingActionBox from "./PendingActionBox";

export const userRatings = [
  {
    id: 1,
    name: "John Doe",
    profilePic: "https://placeimg.com/50/50/people",
    rating: 4,
    description:
      "Please rate us and give us suggestions on how to improve this app. Please rate us and give us suggestions on how to improve this app. ",
    createdAt: "2024-07-20T10:00:00Z",
  },
  {
    id: 2,
    name: "Jane Smith",
    profilePic: "https://placeimg.com/50/50/people",
    rating: 5,
    description:
      "Absolutely fantastic service! Please rate us and give us suggestions on how to improve this app. Please rate us and give us suggestions on how to improve this app. ",
    createdAt: "2024-07-19T12:30:00Z",
  },
  {
    id: 3,
    name: "Alice Johnson",
    profilePic: "https://placeimg.com/50/50/people",
    rating: 3.5,
    description:
      "Good, but there is room for improvement.Please rate us and give us suggestions on how to improve this app. Please rate us and give us suggestions on how to improve this app. ",
    createdAt: "2024-07-18T09:45:00Z",
  },
  {
    id: 4,
    name: "Bob Brown",
    profilePic: "https://placeimg.com/50/50/people",
    rating: 4.5,
    description:
      "Very satisfied, will come back again.Please rate us and give us suggestions on how to improve this app. Please rate us and give us suggestions on how to improve this app. ",
    createdAt: "2024-07-17T14:20:00Z",
  },
  {
    id: 5,
    name: "Charlie Davis",
    profilePic: "https://placeimg.com/50/50/people",
    rating: 2,
    description:
      "Not the best experience, but decent overall.Please rate us and give us suggestions on how to improve this app. Please rate us and give us suggestions on how to improve this app. ",
    createdAt: "2024-07-16T16:10:00Z",
  },
];
const PendingAction = () => {
  const dispatch = useDispatch();
  const [reportedUser, setReportedUser] = useState([]);
  const getAllUsers = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.get(`/api/auth/adminAuth/get/all/reported/users`);
      console.log("reported user", res);
      setReportedUser(res?.data?.pendingReports);
      if (res?.data.success) {
        dispatch(hideLoader());
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: `${error?.response.data.message} !`,
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <>
      <Grid container>
        {reportedUser.map((data, id) => {
          return (
            <PendingActionBox data={data} key={id} getAllUsers={getAllUsers} />
          );
        })}
        <Divider />
      </Grid>
    </>
  );
};

export default PendingAction;
