import React, { useEffect, useState } from 'react'
import { Avatar, Box, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, TextField, Typography, Rating } from '@mui/material';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import axios from 'axios';
import { hideLoader, showLoader } from "../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../ReduxToolkit/Slices/snackbarSlice";
import boldStarIcon from '../../../Assets/solar_star-bold-duotone.svg'
import emptyStarIcon from '../../../Assets/solar_star-empty-duotone.svg'
import FeedBackComments from './FeedBackComments';

const Feedback = () => {
    const [feedBacks, setFeedBacks] = useState([]);
    const dispatch = useDispatch();
    const AllfeedBacks = async () => {
        dispatch(showLoader());
        try {
            const res = await axios.get(`/api/auth/adminAuth/get/all/feedbacks`);
            console.log("res", res)
            if (res?.data.success) {
                dispatch(hideLoader());
                setFeedBacks(res?.data.feedbackData)
            }
        } catch (error) {
            dispatch(hideLoader());

            if (error.response) {
                // Server responded with a status other than 200 range
                console.error('Response error:', error.response.data);
            } else if (error.request) {
                // Request was made but no response received
                console.error('Request error:', error.request);
            } else {
                // Something else happened in setting up the request
                console.error('Error', error.message);
            }
        }
    }
    useEffect(() => {
        AllfeedBacks();
    }, [])
    const generateStarArray = (rating) => {
        const maxRating = 5;
        const resultArray = [];
        for (let i = 1; i <= maxRating; i += 1) {
            if (i <= rating) {
                resultArray.push({ title: `Title ${i}`, symbol: boldStarIcon });
            } else {
                resultArray.push({ title: `Title ${i}`, symbol: emptyStarIcon });
            }
        }
        return resultArray.map((icon, index) => (
            <img key={index} src={icon.symbol} alt={icon.title} style={{ width: "40px", height: "40px" }} />
        ));
    };
    const timeFormater = (date) => {
        const currentTime = dayjs(date);
        const formattedTime = currentTime.format("h:mm A");
        // const formatedDate = currentTime.format("DD-MM-YYYY");
        const formattedDate = currentTime.format("DD MMM, YYYY");

        return `${formattedDate} , ${formattedTime}`;
    };
    return (
        <>
            <Grid container px={1} rowGap={1} columnGap={1} sx={{ height: 500, overflowY: 'auto' }}>
                {feedBacks.length === 0 ? "No data Found" : feedBacks?.map((data, index) => {
                    return <Grid key={data._id} component={Paper} item md={9} sm={5.7} p={1.5} sx={{ border: "1px solid rgba(200, 200, 200, 1)", borderRadius: "5px", }}>
                        <Stack spacing={.7} width={"95%"}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <List>
                                    <ListItem alignItems='start'>
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{ width: "40px", height: "40px" }}
                                                src={data.submitBy ? data.submitBy.profilePicture.imageUrl : "N/A"}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            sx={{
                                                "& .MuiListItemText-primary": {
                                                    fontSize: "14.53px",
                                                    fontWeight: "500",
                                                },
                                                "& .MuiListItemText-secondary": {
                                                    fontSize: "12.33px",
                                                    fontWeight: "400",

                                                },
                                            }}
                                            primary={`${data.submitBy ? data.submitBy.name : "N/A"}`}
                                        // secondary={data.queryType}
                                        />

                                    </ListItem>
                                </List>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography fontSize="13px" sx={{ ml: .5 }} fontWeight="regular">{timeFormater(data.createdAt)}</Typography>
                                </Box>

                            </Box>
                            <Box sx={{ display: "flex" }}>
                                {generateStarArray(data.rating)}
                                {/* <Rating

                                    sx={{
                                        fontSize: '40px',
                                        background: 'none',
                                        backgroundClip: 'text',
                                        color: `linear-gradient(to right, #2ECC71, #BDC3C7)`,
                                        '& .MuiRating-iconFilled': {
                                            position: "relative",
                                            color: 'rgba(19, 136, 8, 1)',
                                            // maskClip:"linear-gradient(to right, #2ECC71, #BDC3C7)",
                                            // maskImage:"linear-gradient(to right, #2ECC71, #BDC3C7)",
                                            // "&::before":{
                                            //     content:'""',
                                            //     width:"50%",
                                            //     position:"absolute",
                                            //     top:0,
                                            //     right:0,
                                            //     backgroundColor:"black",
                                            //     color:"green",

                                            // }

                                        },
                                    }}
                                    size="large"
                                    key={index}
                                    name="read-only"
                                    value={data.rating}
                                    readOnly
                                // icon={<img src={FavoriteIcon} alt="fill rating icon" style={{ width: "40px", height: "40px" }} />}
                                // emptyIcon={<img alt="empty rating icon" src={FavoriteBorderIcon} style={{ width: "40px", height: "40px" }} />}
                                // precision={0.5}
                                /> */}

                            </Box>
                            <FeedBackComments data={data} />

                        </Stack>
                    </Grid>
                })}
            </Grid >

        </>
    )
}

export default Feedback