import React, { useState } from "react";
import { Box, Stack, Avatar, Grid, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

const RemovedUsersBox = ({ data, getAllUsers }) => {
  const [showFullText, setShowFullText] = useState(false);
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={10}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        borderBottom: "1px solid lightgray",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={data?.reported?.profilePicture?.imageUrl}
          sx={{ width: "50px", height: "50px" }}
        />
        <Stack ml={3}>
          <Typography sx={{ fontSize: "20px" }}>
            {data.reported ? data.reported.name : "N/A"}
          </Typography>
          <Typography
            sx={{
              color: "rgba(106, 106, 106, 1)",
              fontSize: "14.25px",
              fontWeight: 350,
            }}
          >{`Reported By ${
            data.reportedBy ? data.reportedBy.name : "N/A"
          }`}</Typography>
          <Typography
            sx={{
              color: "rgba(106, 106, 106, 1)",
              fontSize: "14.25px",
              fontWeight: 350,
            }}
          >{`Reason : ${data.reason ? data.reason : "N/A"}`}</Typography>
        </Stack>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography
          sx={{
            color: "rgba(255, 153, 0, 1)",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {data.actionOnReport === "user deactivated"
            ? "User Deactivated"
            : "N/A"}
        </Typography>
      </Box>
    </Grid>
  );
};
RemovedUsersBox.propTypes = {
  data: PropTypes.array,
  getAllUsers: PropTypes.func,
};

export default RemovedUsersBox;
