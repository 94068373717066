import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Box, Typography } from '@mui/material'

const FeedBackComments = ({ data }) => {
    const [showFullText, setShowFullText] = useState(false);

    return (
        <Box px={1} width={'95%'} py={.5}>
            {`${data.comment}`.length >= 120 ? <Typography fontSize="13px" >
                {showFullText ? data.comment : data.comment.slice(0, 150)}...
                <Typography component={'span'} onClick={() => setShowFullText(!showFullText)} style={{ cursor: 'pointer', color: 'rgba(106, 106, 106, 1)', fontSize: "13.25px", fontWeight: 400 }}>
                    {showFullText ? ' Read less' : ' Read more'}
                </Typography>
            </Typography> : <Typography fontSize="13px">{data.comment}</Typography>}

        </Box>
    )
}
FeedBackComments.propTypes = {
    data: PropTypes.object,
};
export default FeedBackComments