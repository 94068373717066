import { Helmet } from "react-helmet-async";
import { useState } from "react";
import {
  Box,
  Grid,
  Checkbox,
  IconButton,
  Typography,
  InputAdornment,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { hideLoader, showLoader } from "../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../ReduxToolkit/Slices/snackbarSlice";
import { getErrorMessage } from "../reusablecomponents/CustomError/errorMessages";

function ForgotPassword() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const label = {
    inputProps: { label: "Remember Me", "aria-label": "Checkbox demo" },
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const authentication = async (e) => {
    e.preventDefault();
    dispatch(showLoader());

    try {
      const res = await axios.post(`/api/auth/adminAuth/forgot/password`, {
        email,
      });
      console.log("response", res);
      if (res?.data.success) {
        dispatch(
          showSnackbar({
            message: `OTP has been sent to your account`,
            severity: "success",
          }),
        );
        dispatch(hideLoader());
        setEmail("");
        navigate("/verification", { state: { email } });
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Grid
        container
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url("/assets/Background.png")`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          component={"form"}
          onSubmit={authentication}
          item
          xs={11}
          md={6}
          lg={3.5}
          sm={8.5}
          sx={{ bgcolor: "white", borderRadius: "8px" }}
        >
          <Stack
            rowGap={1}
            sx={{
              px: 3,
              py: 6,
            }}
          >
            <Stack spacing={3}>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(51, 51, 51, 1)",
                    fontSize: "25px",
                    fontWeight: 400,
                  }}
                >
                  Forgot Password{" "}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "rgba(51, 51, 51, 1)",
                    fontSize: "15px",
                    fontWeight: 400,
                  }}
                >
                  Enter your email, and we will send you instructions to reset
                  your password.
                </Typography>
              </Box>
              <Stack spacing={1}>
                <Typography sx={{ color: "rgba(51, 51, 51, 1)" }}>
                  Email
                </Typography>
                <TextField
                  type="text"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  id="outlined-required"
                  variant="outlined"
                  required
                />
              </Stack>
            </Stack>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "rgba(82, 118, 10, 1)",
                  boxShadow: "none",
                  color: "white",
                },
                mt: "10px",
              }}
            >
              Send code
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default ForgotPassword;
