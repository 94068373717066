import React, { useState, useEffect } from "react";

import {
  Box,
  CardMedia,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  hideLoader,
  showLoader,
} from "../../../../ReduxToolkit/Slices/loaderSlice";
import { showSnackbar } from "../../../../ReduxToolkit/Slices/snackbarSlice";
import DeleteModal from "../../../../reusablecomponents/DeleteModal/DeleteModal";
import UploadIcon from "../../../../Assets/solar_upload-line-duotone.png";
import YellowAvatar from "../../../../Assets/yellowAvatar.png";
import BlueAvatar from "../../../../Assets/blueAvatar.png";
import PurpleAvatar from "../../../../Assets/purpuleAvatar.png";
import RedPurpleAvatar from "../../../../Assets/redPurpleAvatar.png";
import TrashIcon from "../../../../Assets/solar_trash-bin-2-bold-duotone.svg";
import { getErrorMessage } from "../../../../reusablecomponents/CustomError/errorMessages";

const avatarData = [
  {
    id: 1,
    title: "Golden Ape",
    icon: YellowAvatar,
  },
  {
    id: 2,
    title: "Blue Ape",
    icon: BlueAvatar,
  },
  {
    id: 3,
    title: "Purple Ape",
    icon: PurpleAvatar,
  },
  {
    id: 4,
    title: "Purple Ape",
    icon: RedPurpleAvatar,
  },
];
const ManageAvatar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [avatars, setavatars] = useState([]);
  const [fileSize, setFileSize] = useState(null);
  const [DeletedId, setDeletedId] = useState([]);
  // To open delete modal
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    buttonText: "",
    data: {
      id: null,
    },
  });
  const handleDeleteImages = (index) => {
    setImages((prevImages) => prevImages?.filter((image, i) => i !== index));
  };
  const getAllAvatar = async (e) => {
    dispatch(showLoader());

    try {
      const res = await axios.get(`/api/auth/adminAuth/get/all/avatars`);
      dispatch(hideLoader());
      setavatars(res?.data?.data);
    } catch (error) {
      if (error.response) {
        dispatch(hideLoader());
        console.error("Response error:", error.response.data);
      } else if (error.request) {
        console.error("Request error:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  useEffect(() => {
    getAllAvatar();
  }, []);
  const createFormData = async () => {
    const formData = new FormData();
    if (images && images.length > 0) {
      images.forEach((img) => {
        formData.append("file", img);
      });
    }
    formData.append("avatarName", title);

    return formData;
  };
  const addAvatar = async (e) => {
    e.preventDefault();
    if (images.length === 0) {
      dispatch(
        showSnackbar({ message: "Avatar is compulsory", severity: "error" }),
      );
      return;
    }
    dispatch(showLoader());
    try {
      const getFormData = await createFormData();
      const data = await axios.post(
        `/api/auth/adminAuth/add/avatar`,
        getFormData,
      );
      setTitle("");
      getAllAvatar();
      setImages([]);
      dispatch(
        showSnackbar({
          message: "Avatar added successfully!",
          severity: "success",
        }),
      );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    const imagesArray = [];
    const maxFileSizeMB = 2;
    const maxImages = 1;
    if (files.length > 1) {
      dispatch(
        showSnackbar({
          message: "Only one avatar can be uploaded",
          severity: "error",
        }),
      );
      return;
    }
    if (images.length > 0) {
      dispatch(
        showSnackbar({
          message: "Only one avatar can be uploaded",
          severity: "error",
        }),
      );
      return;
    }
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const fileType = file.type.split("/")[0];
      const sizeInMB = file.size / 1024 / 1024;
      if (sizeInMB > maxFileSizeMB) {
        dispatch(
          showSnackbar({
            message: "File size should be max 2 MB",
            severity: "error",
          }),
        );
        break;
      }
      // Check if the file type is image and not more than 5 images
      if (fileType === "image") {
        if (imagesArray.length < maxImages) {
          imagesArray.push(file);
        } else {
          dispatch(
            showSnackbar({
              message: "Only 1 avatar can be uploaded",
              severity: "error",
            }),
          );
          break;
        }
      } else {
        dispatch(
          showSnackbar({
            message: "Videos are not allowed",
            severity: "error",
          }),
        );
        break;
      }
    }
    setImages((prevImages) => [...prevImages, ...imagesArray]);
  };
  const handleClose = () => {
    setDialog({
      open: false,
      title: "",
      message: "",
      buttonText: "",
    });
  };

  const handleDelete = async () => {
    dispatch(showLoader());
    try {
      const res = await axios.delete(
        `/api/auth/adminAuth/delete/avatar?avatarId=${dialog.data.id}`,
      );
      getAllAvatar();
      if (res?.data.success) {
        dispatch(hideLoader());
        setDialog({
          open: false,
          title: "",
          buttonText: "",
          data: {
            id: null,
          },
        });
        dispatch(
          showSnackbar({
            message: `Avatar deleted successfully!`,
            severity: "success",
          }),
        );
      }
    } catch (error) {
      dispatch(
        showSnackbar({
          message: error?.response?.data.message
            ? error?.response?.data.message
            : getErrorMessage(error?.response?.status),
          severity: "error",
        }),
      );
      dispatch(hideLoader());
    }
  };
  return (
    <>
      <Grid container columnGap={2} rowGap={3}>
        <Grid
          item
          xs={6}
          component={Paper}
          elevation={2}
          md={7}
          sx={{ display: "flex", p: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: 250,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                color: "rgba(110, 107, 123, 1)",
              }}
            >
              Add Avatar
            </Typography>
          </Box>
          <Stack
            spacing={3}
            width={"60%"}
            pr={5}
            component={"form"}
            onSubmit={addAvatar}
          >
            <Box
              py={1.2}
              component={"label"}
              border={"2px dashed gray"}
              borderRadius={"10px"}
              htmlFor="image-upload"
              xs={12}
              md={4}
              minHeight={100}
              maxHeight={200}
              height={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              bgcolor={"primary"}
              sx={{ cursor: "pointer", bgcolor: "rgba(243, 243, 243, 1)" }}
            >
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                hidden
                onChange={handleFileChange}
              />
              <img
                src={UploadIcon}
                alt="upload icon"
                style={{ width: "25px", height: "25px" }}
              />
              <Typography fontSize="15px">Upload Avatar</Typography>
              <Typography fontSize="10px">Max : 2 MB / avatar</Typography>
            </Box>
            {images.map((image, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: -20,
                      left: 40,
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteImages(index)}
                    aria-label="delete"
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
                <CardMedia
                  component="img"
                  image={URL.createObjectURL(image)}
                  alt={`Selected Image ${index}`}
                  sx={{ width: "50px", height: "50px" }}
                />
              </Box>
            ))}
            <Box>
              <TextField
                fullWidth
                placeholder="Write Title here"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                required
                label="Avatar Name"
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{
                  width: "150px",
                  "&:hover": {
                    backgroundColor: "rgba(82, 118, 10, 1)",
                    boxShadow: "none",
                    color: "white",
                  },
                }}
              >
                save
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box py={4}>
        <Typography
          sx={{
            color: "rgba(110, 107, 123, 1)",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          All Avatar
        </Typography>
      </Box>
      <Grid container rowGap={1.5}>
        {avatars.length === 0
          ? "No Data found"
          : avatars?.map((avat, index) => {
              return (
                <Grid
                  component={Paper}
                  elevation={3}
                  item
                  xs={12}
                  md={7}
                  key={index}
                  sx={{
                    p: 2.5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      // component={Paper}
                      sx={{
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
                        // bgcolor: "red",
                        width: "75px",
                        height: "80px",
                        // pt:.5
                      }}
                    >

                      <Avatar
                        alt="userProfileImage"
                        src={avat.avatarFile.avatarUrl}
                        sx={{
                          width: "60px",
                          height: "72px",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      id="title"
                      value={avat.avatarName}
                      variant="outlined"
                      label="Name"
                      disabled
                      sx={{
                        width: "300px",
                        "&>.Mui-disabled": {
                          // opacity: 0.5, // adjust opacity to make it look disabled
                          color: "rgba(134, 132, 139, 1)", // adjust background color to make it look disabled
                          borderColor: "rgba(134, 132, 139, 1) !important", // adjust border color to make it look disabled
                          "& .MuiInputBase-input": {
                            cursor: "not-allowed", // change cursor to indicate it's disabled
                            borderColor: "rgba(134, 132, 139, 1) !important",
                          },
                        },
                        "&.MuiInputBase-input::placeholder": {
                          color: "red", // style the placeholder text
                        },
                      }}
                    />
                  </Box>
                  <Stack
                    alignItems={"center"}
                    onClick={() =>
                      setDialog({
                        open: true,
                        title: "avatar",
                        buttonText: "",
                        data: {
                          id: avat._id,
                        },
                      })
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    <img
                      src={TrashIcon}
                      alt="trashIcon"
                      style={{ width: "35px", height: "35px" }}
                    />
                    <Typography>Delete</Typography>
                  </Stack>
                </Grid>
              );
            })}
      </Grid>
      {dialog.open && (
        <DeleteModal
          open={dialog.open}
          handleClose={handleClose}
          dialog={dialog}
          deleteFunc={() => handleDelete()}
        />
      )}
    </>
  );
};

export default ManageAvatar;
